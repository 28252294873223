import React, { FC } from 'react';

import { Box, Button, Heading, IconName, PicnicCss, Text } from '@attentive/picnic';

import { DisplayableOption } from '../../../constants';

import { Tag } from './Tag';

const wrapperCss: PicnicCss = {
  height: 140,
  pt: '$space4',
  overflow: 'auto',
  borderTop: '$borderWidths$borderWidth1 solid $borderLoud',
};

export type SelectedOption = DisplayableOption & {
  icon?: IconName;
  disabled?: boolean;
};

interface SelectedItemsWrapperComponentProps {
  selectedOptions: SelectedOption[];
  onRemoveSelection: (option: DisplayableOption) => void;
  onClearAll?: () => void;
  itemTypeLabel: string;
  shouldShowSelectedCount?: boolean;
  css?: PicnicCss;
  tagCss?: PicnicCss;
}

const SelectedItemsWrapper: FC<SelectedItemsWrapperComponentProps> = ({
  selectedOptions,
  onRemoveSelection,
  onClearAll,
  itemTypeLabel,
  shouldShowSelectedCount,
  css = {},
  tagCss = {},
}) => {
  return (
    <Box css={{ ...wrapperCss, ...css }}>
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Heading variant="sm" css={{ mb: onClearAll ? '$space0' : '$space2' }} as="h2">
          Selected {itemTypeLabel.toLocaleLowerCase()}{' '}
          {shouldShowSelectedCount ? `(${selectedOptions.length})` : ''}
        </Heading>
        {onClearAll && (
          <Button
            onClick={onClearAll}
            disabled={!selectedOptions.length}
            variant="subdued"
            size="small"
          >
            Clear all
          </Button>
        )}
      </Box>
      <div>
        {!selectedOptions.length && (
          <Text>{itemTypeLabel} will appear here when you select them.</Text>
        )}
        <Box css={{ display: 'flex', flexWrap: 'wrap' }} data-testid="selected-tags">
          {selectedOptions.map((option) => (
            <Tag
              key={option.optionValue}
              content={option.displayName}
              onDelete={() => onRemoveSelection(option)}
              css={{ margin: '$space0 $space2 $space2 $space0' }}
              icon={option.icon}
              tagCss={tagCss}
              disabled={option.disabled}
            />
          ))}
        </Box>
      </div>
    </Box>
  );
};

SelectedItemsWrapper.displayName = 'SelectedItemsWrapepr';
export { SelectedItemsWrapper };
