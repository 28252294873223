import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';

import { ToastType, useToast, useCurrentCompanyId } from '@attentive/acore-utils';
import { useQuery } from '@attentive/data/react-query';
import { isEqual } from '@attentive/nodash';

import {
  fetchWorkingSegmentCount,
  fetchCreatedSegmentCount,
  fetchLocationData,
  fetchSegment,
  fetchSegmentableAttributesByVendor,
  fetchSegmentOptions,
  fetchCreatedSegmentCountV2,
  fetchWorkingSegmentCountV2,
  FetchSegmentResponse,
} from '../api';
import {
  subscriberAttributeDataTypeMapAtom,
  vendorAttributeDataTypeMapAtom,
} from '../components/SegmentCreateEditModal/utils/attributeDataTypeMap';
import {
  BlankSegment,
  Segment,
  NumberValueDisplayableOption,
  SegmentOptions,
  SegmentSubscriberCount,
  ThinSegment,
  LocationType,
  SegmentableAttributes,
  SegmentDuplicates,
  SegmentSubscriberCountV2,
} from '../constants';
import { getNewBlankSegment, getDuplicates, isValidSegmentCondition } from '../utils';

import { useUserIsDemoUser } from './useUserIsDemoUser';

export const SEGMENT_DATA_STALE_TIME = 10000;
export const SEGMENT_COUNT_STALE_TIME = 5000;

export function useSegment(
  segmentId: string | number | null | undefined,
  isDemoUser: boolean,
  useQueryOptions = {}
) {
  const [createToast] = useToast();
  const {
    data,
    error,
    isFetching,
    refetch: getSegment,
  } = useQuery<FetchSegmentResponse | null, Error>(
    ['fetchSegment', String(segmentId)],
    () => {
      if (Number.isNaN(Number(segmentId))) {
        return null;
      }
      return fetchSegment(Number(segmentId), isDemoUser);
    },
    {
      ...useQueryOptions,
      placeholderData: null,
      onError: (err) => {
        createToast({
          type: ToastType.Error,
          title: 'Unable to fetch segment details',
          text: err.message,
        });
      },
    }
  );

  const { segment, status } = data || {};

  return {
    segment: status !== 200 ? null : segment,
    status,
    getSegment,
    isFetching,
    error: error && 'Unable to fetch segment details',
  };
}

export const useSegmentCreateEdit = (
  segmentId: number | string | undefined,
  passedSegment: BlankSegment | Segment | ThinSegment | undefined,
  isDemoUser: boolean
) => {
  const normalizedSegment = passedSegment
    ? {
        ...passedSegment,
        expressions: (passedSegment as Segment).expressions || [],
      }
    : null;
  const initialSegment = normalizedSegment || getNewBlankSegment();
  const [segment, setSegment] = useState<Segment | BlankSegment>(initialSegment);
  const [duplicates, setDuplicates] = useState<SegmentDuplicates>(new Map());
  // With our current implementation, there's a point where isFetching is false but the fetched segment is not being returned by the hook
  // This is due to our use of state for storing the segment. isSegmentLoaded indicates that the segment has been fetched and stored in state.
  const [isSegmentLoaded, setIsSegmentLoaded] = useState(false);

  const { segment: fetchedSegment, error, isFetching } = useSegment(segmentId, isDemoUser);

  useEffect(() => {
    if (fetchedSegment) {
      setSegment(fetchedSegment);
      setIsSegmentLoaded(true);
    }
  }, [fetchedSegment]);

  useEffect(() => {
    if (isFetching && isSegmentLoaded) {
      setIsSegmentLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const updateCurrentSegment = useCallback(
    (values: Partial<Segment>) => {
      const newState = { ...segment, ...values };
      if (!isEqual(newState, segment)) {
        setDuplicates(getDuplicates(newState.expressions));
        setSegment(newState);
      }
    },
    [segment, setSegment]
  );

  const hasDuplicates = duplicates.size > 0;

  return {
    segment,
    updateCurrentSegment,
    duplicates,
    hasDuplicates,
    isSegmentLoaded,
    error,
  };
};

export const useSegmentOptions = (useQueryOptions = {}) => {
  const companyId = useCurrentCompanyId();
  const [createToast] = useToast();

  return useQuery<SegmentOptions | null, Error>(
    ['segmentOptions', companyId],
    () => {
      return fetchSegmentOptions().then((response) => {
        const { body, status } = response;
        if (status !== 200) {
          throw new Error('Segment options failed to load');
        }
        if (!body) {
          throw new Error('Unexpected empty response');
        }
        return body;
      });
    },
    {
      ...useQueryOptions,
      onError: (error) => {
        createToast({
          type: ToastType.Error,
          title: 'Unable to fetch segment options',
          text: error.message,
        });
      },
    }
  );
};

export const useLocationData = (
  itemId: string | undefined,
  itemIdType: LocationType,
  locationType: LocationType,
  useQueryOptions = {}
) => {
  const [createToast] = useToast();

  return useQuery<NumberValueDisplayableOption[] | null, Error>(
    [itemId, itemIdType, locationType],
    () => {
      if (!itemId) {
        return Promise.resolve([]);
      }

      return fetchLocationData(itemId, itemIdType, locationType).then((response) => {
        const { body, status } = response;
        if (status !== 200) {
          throw new Error('Location data failed to load');
        }
        if (!body) {
          throw new Error('Unexpected empty response');
        }
        return body;
      });
    },
    {
      ...useQueryOptions,
      onError: (error) => {
        createToast({
          type: ToastType.Error,
          title: 'Unable to fetch location data',
          text: error.message,
        });
      },
    }
  );
};

export const useSegmentableAttributesByVendor = (useQueryOptions = {}) => {
  const companyId = useCurrentCompanyId();
  const [createToast] = useToast();

  const { data } = useQuery<{ segmentableAttributesByVendor: SegmentableAttributes } | null, Error>(
    ['segmentableAttributesByVendor', companyId],
    () => {
      return fetchSegmentableAttributesByVendor().then((response) => {
        const { body, status } = response;
        if (status !== 200) {
          throw new Error('Segmentable attributes by vendor failed to load');
        }
        if (!body) {
          throw new Error('Unexpected empty response');
        }
        return body;
      });
    },
    {
      ...useQueryOptions,
      onError: (error) => {
        createToast({
          type: ToastType.Error,
          title: 'Unable to fetch segmentable attributes by vendor',
          text: error.message,
        });
      },
    }
  );

  return data?.segmentableAttributesByVendor || {};
};

export const useWorkingSegmentCount = (segment: Segment | BlankSegment, useQueryOptions = {}) => {
  const [createToast] = useToast();
  const expressionsKey = JSON.stringify(segment.expressions);

  return useQuery<SegmentSubscriberCount | null, Error>(
    ['workingSegmentCount', expressionsKey],
    () => {
      return fetchWorkingSegmentCount(segment).then((response) => {
        const { body, status } = response;
        if (status !== 200) {
          throw new Error(`Segment count failed to load: ${JSON.stringify(segment)}`);
        }
        if (!body) {
          throw new Error('Unexpected empty response');
        }
        return body;
      });
    },
    {
      ...useQueryOptions,
      onError: (error) => {
        createToast({
          type: ToastType.Error,
          title: 'Unable to fetch segment count',
          text: error.message,
        });
      },
    }
  );
};

export const useWorkingSegmentCountV2 = (segment: Segment | BlankSegment, useQueryOptions = {}) => {
  const [createToast] = useToast();
  const expressionsKey = `${segment.operator}${JSON.stringify(segment.expressions)}`;

  return useQuery<SegmentSubscriberCountV2 | null, Error>(
    ['workingSegmentCountV2', expressionsKey],
    () => {
      return fetchWorkingSegmentCountV2(segment).then((response) => {
        const { body, status } = response;
        if (status !== 200) {
          throw new Error(`Segment count failed to load: ${JSON.stringify(segment)}`);
        }
        if (!body) {
          throw new Error('Unexpected empty response');
        }
        return body;
      });
    },
    {
      ...useQueryOptions,
      onError: (error) => {
        createToast({
          type: ToastType.Error,
          title: 'Unable to fetch segment count',
          text: error.message,
        });
      },
    }
  );
};

export const useCreatedSegmentCount = (
  segmentId: string | number,
  isDemoUser: boolean,
  useQueryOptions = {}
) => {
  const [createToast] = useToast();

  const {
    data: segmentCount,
    isFetching,
    error,
    refetch: getSegmentCount,
  } = useQuery<SegmentSubscriberCount | null, Error>(
    ['createdSegmentCount', String(segmentId)],
    () => fetchCreatedSegmentCount(Number(segmentId), isDemoUser),
    {
      ...useQueryOptions,
      onError: (err) => {
        createToast({
          type: ToastType.Error,
          title: 'Unable to fetch segment count',
          text: err.message,
        });
      },
    }
  );

  return {
    segmentCount,
    isFetching,
    error: error && 'Unable to fetch segment count',
    getSegmentCount,
  };
};

export interface CreatedSegmentCountV2 {
  segmentCount: SegmentSubscriberCountV2 | null | undefined;
  isFetching: boolean;
  error: string | null;
  getSegmentCount: () => void;
}

export const useCreatedSegmentCountV2 = (
  segmentId: string | number,
  useQueryOptions = {}
): CreatedSegmentCountV2 => {
  const [createToast] = useToast();
  const isDemoUser = useUserIsDemoUser();

  const {
    data: segmentCount,
    isFetching,
    error,
    refetch: getSegmentCount,
  } = useQuery<SegmentSubscriberCountV2 | null, Error>(
    ['createdSegmentCountV2', String(segmentId)],
    () => fetchCreatedSegmentCountV2(Number(segmentId), isDemoUser),
    {
      ...useQueryOptions,
      onError: (err) => {
        createToast({
          type: ToastType.Error,
          title: 'Unable to fetch segment count',
          text: err.message,
        });
      },
    }
  );

  return {
    segmentCount,
    isFetching,
    error: error && 'Unable to fetch segment count',
    getSegmentCount,
  };
};

// returns true if any segment expression has an invalid component
export const useSegmentIsInvalid = (segment: Segment | BlankSegment) => {
  const subscriberAttributeDataTypeMap = useAtomValue(subscriberAttributeDataTypeMapAtom);
  const vendorAttributeDataTypeMap = useAtomValue(vendorAttributeDataTypeMapAtom);

  return segment.expressions.some((expression) =>
    expression.segmentComponents.some(
      (component) =>
        !isValidSegmentCondition(
          component.parameters,
          subscriberAttributeDataTypeMap,
          vendorAttributeDataTypeMap
        )
    )
  );
};
